import React, { useState, useEffect } from "react";
import { CustomButton } from "@components/custom-components/customButtons";
import { SimpleModal } from "@components/common/simpleModal.component";
import { NoWalletPopUp } from "@components/common/noWalletPopup.component";
import { Modal } from "react-bootstrap";
import useTranslation from "next-translate/useTranslation";
import { useAuth } from "@hooks/useAuth";
import { useSubscription } from "@hooks/useSubscription";
import { useCards } from "@hooks/useCards";
import { useFollow } from "@hooks/useFollow";
import router from "next/router";
import _get from "lodash/get";
import {PushSubscribe} from "@components/push-subscribe/push-subscribe.component";

export default function SubscriptionButton({ channel, setTempFollowing }: any) {
  const { t } = useTranslation("common");
  const { user } = useAuth();
  const [modal, setModal] = useState<boolean>(false);
  const [showNoWallet, setShowNoWallet] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [unSubs] = useState({ id: null, subscriptionId: null });
  const [{ card }] = useCards();
  const [following, { follow, unfollow, checkIsFollowing }] = useFollow();
  const [tmpFollow, setTmpFollow] = useState<number>(0);
  const [loading, setLoading] = useState(true); // State to track loading
  const [subscribed, { unSubscribe, subscribe, getChannelSubscription }] = useSubscription();
  const channelId = channel?.id ?? channel?._id
  // New state to track subscription status
  const [isSubscribedToChannel, setIsSubscribedToChannel] = useState(false);


  useEffect(() => {
    if (user && channelId) {
      getChannelSubscription().then(() => {
        setLoading(false); // Set loading to false after everything has loaded
      });
    }
    // eslint-disable-next-line
  }, [user, channelId]);

  useEffect(() => {
    if (!loading && user && channelId) {
      const fetchData = async () => {
        await getChannelSubscription();
        const subDetails = subscribed.subDetails || [];
        const isSubscribed = subDetails.some((sub: { channel: any }) => sub.channel === channelId);
        setIsSubscribedToChannel(isSubscribed);
        checkIsFollowing(channelId);
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [loading, user, channelId]);

  const toggle = () => {
    setModal(!modal);
  };

  const validateCard = () => {
    setShowNoWallet(!_get(card, "id", false));
  };

  return (
    <>
      <div className="at-btnarea">
        { !user && (
          <CustomButton
            class="button primary"
            label={t("banner.follow")}
            loading={false}
            disabled={false}
            type="button"
            onClick={() => {
              router.push('/login')
            }}
          />
        )}

        {user && (tmpFollow === 1 || tmpFollow === 0 && following) ? (
          <CustomButton
            class="button fourth"
            label={t("banner.unfollow")}
            loading={false}
            disabled={false}
            type="button"
            onClick={() => {
              unfollow(channelId);
              setTmpFollow(-1);
              setTempFollowing((s: number) => s - 1)
            }}
          />
        ) : user && (tmpFollow === -1 || tmpFollow === 0 && !following) && (
          <CustomButton
            class="button fourth"
            label={t("banner.follow")}
            loading={false}
            type="button"
            onClick={() => {
              follow(channelId);
              setTmpFollow(1);
              setTempFollowing((s: number) => s + 1)
            }}
          />
        )}

        {user && isSubscribedToChannel && (
          <React.Fragment>
            <CustomButton
              label={t("banner.subscribed")}
              icon
              class={"button primary"}
              onClick={() => {setConfirmation(!confirmation); validateCard();}}
              loading={false}
              type="button"
            />
            {confirmation && (
              <SimpleModal
                label="submit"
                onClick={() => {
                  unSubscribe(channelId, user.id)
                }}
                default={true}
                toggle={() => {setConfirmation(!confirmation); validateCard();}}
                data={unSubs}
                title={t("banner.unsubscribe")}
                text={t("settings.subscription.unsubPrompt")}
              />
            )}
          </React.Fragment>
        )}
        {user && channel?.price >0 && !isSubscribedToChannel && (
          <React.Fragment>
            <CustomButton
              label={`${t("banner.subscribe")} (${channel?.price}€)`}
              class={"button primary"}
              onClick={() => {setConfirmation(!confirmation); validateCard();}}
              disabled={false}
              loading={false}
              type="button"
            />
            <NoWalletPopUp show={showNoWallet} setShow={setShowNoWallet} />
            {confirmation && _get(card, "id", false) && (
              <SimpleModal
                label="submit"
                onClick={() => {
                  subscribe(channelId, channel['room'])
                }}
                title={t("banner.subscribe")}
                default={true}
                toggle={() => {setConfirmation(!confirmation); validateCard();}}
                text={
                  t("subscribeModal.description") +
                  " " +
                  channel?.price +
                  " EUR " +
                  t("subscribeModal.period")
                }
                data={unSubs}
              />
            )}
          </React.Fragment>
        )}
        {channel?.price > 0 && !user && (
          <CustomButton
            label={t("banner.subscribe")}
            class={"button primary"}
            onClick={() => router.push("/login")}
            disabled={false}
            loading={false}
            type="button"
          />
        )}
      </div>
      {/* <PushSubscribe></PushSubscribe> */}
      <Modal show={modal} className="at-thememodal" onHide={toggle} centered>
        <Modal.Header>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="at-modalcontentarea">
            <form className="at-themefrome">
              <span className="at-text">Are you sure you want to subscribe?</span>
              <div className="from-group">
                <button type="button" className="at-btn">
                  Yes
                </button>
                <button type="button" className="at-btn">
                  No
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
