import { HttpService } from "@services/base";

class SubscriptionsService extends HttpService {
  private readonly prefix: string = "v2/subscriptions";

  follow = (body: any): Promise<any> => this.post(`${this.prefix}`, body);

  findAll = (): Promise<any> => this.get(`${this.prefix}/?v=${new Date().getTime()}`);

  unfollow = (channelId: string): Promise<any> =>
    this.delete(`${this.prefix}/${channelId}`);
}

export const subscriptionsService = new SubscriptionsService();
