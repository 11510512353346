import { useState, useCallback, useEffect } from "react";
import { subscriptionsService } from "@services/subscriptions";
import {notify} from "@utils/toast.utils";

export const useFollow = (): any => {
const [following, setFollowing] = useState<any>(null);
const [error, setError] = useState<any>(null);

  const follow = async (channelId: string) => {
    try {
      await subscriptionsService.follow({channel: channelId});
      setFollowing(true);
      notify("success", "Ahora sigues a este canal", {theme: 'colored'});
    } catch (err) {
      notify("error", "Ya estás siguiendo a este canal", {theme: 'colored'});
    }
  };

  const checkIsFollowing = useCallback(async (channelId: string) => {
    try {
      const {data} = await subscriptionsService.findAll();
      setFollowing(!!data.find((follow: any) => follow.subscribedTo === channelId));
    } catch (err) {
      setError(err)
      // notify("error", "Ocurrió un error", {theme: 'colored'});
    }
  },[])

  const unfollow = async (channelId: string) => {
    try {
      await subscriptionsService.unfollow(channelId);
      setFollowing(false);
      notify("success", "Dejaste de seguir a este canal", {theme: 'colored'});
    } catch (err) {
      notify("error", "No estás siguiendo a este canal", {theme: 'colored'});
    }
  };

  useEffect(() => {
    if (error) {
      console.warn("useFollow.checkIsFollowing ::", error?.message)
    }
  }, [error])

  return [following, { follow, unfollow, checkIsFollowing }];
};
